{
  "name": "enrollhere-web",
  "version": "0.1.15",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch",
    "test": "ng test",
    "dev:ssr": "ng run enrollhere-web:serve-ssr",
    "serve:ssr": "node dist/enrollhere-web/server/main.js",
    "build:ssr": "ng build && ng run enrollhere-web:server",
    "prerender": "ng run enrollhere-web:prerender",
    "deploy-major": "npm version major && git add . && git push --tags && git push origin main:main",
    "deploy-minor": "npm version minor && git add . && git push --tags && git push origin main:main",
    "deploy": "npm version patch && git add . && git push --tags && git push origin main:main",
    "upload-dev": "firebase deploy --only hosting:enrollhere --project enroll-here-dev ",
    "upload-prod": "firebase deploy --only hosting:enrollhere --project enroll-here",
    "replace-timestamp": "node ./replace.build.js",
    "lint": "ng lint",
    "deploy:prod:temp": " ng build --c production &&  firebase deploy --only hosting:enrollhere --project enroll-here --config firebase.temp.json",
    "deploy:demo:temp": "mv ./src/environments/environment.ts ./src/environments/environment.prod.ts  && cp ./src/environments/environment.demo.ts ./src/environments/environment.ts && ng build --c production &&  firebase deploy --only hosting:enrollhere --project enroll-here-demo --config firebase.temp.json && mv ./src/environments/environment.prod.ts ./src/environments/environment.ts",
    "deploy:dev:temp": "mv ./src/environments/environment.ts ./src/environments/environment.prod.ts  && cp ./src/environments/environment.development.ts ./src/environments/environment.ts && ng build --c production &&  firebase deploy --only hosting:enrollhere --project enroll-here-dev --config firebase.temp.json && mv ./src/environments/environment.prod.ts ./src/environments/environment.ts",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org enrollhere --project enrollhere-web ./dist/enrollhere-web/browser && sentry-cli sourcemaps upload --org enrollhere --project enrollhere-web ./dist/enrollhere-web/browser"
  },
  "private": true,
  "dependencies": {
    "@almothafar/angular-signature-pad": "^5.0.1",
    "@angular-devkit/architect": "^0.1703.4",
    "@angular-devkit/core": "^17.3.4",
    "@angular/animations": "^17.3.4",
    "@angular/cdk": "^17.3.4",
    "@angular/common": "^17.3.4",
    "@angular/compiler": "^17.3.4",
    "@angular/core": "^17.3.4",
    "@angular/fire": "^17.0.1",
    "@angular/forms": "^17.3.4",
    "@angular/google-maps": "^17.3.4",
    "@angular/platform-browser": "^17.3.4",
    "@angular/platform-browser-dynamic": "^17.3.4",
    "@angular/platform-server": "^17.3.4",
    "@angular/router": "^17.3.4",
    "@angular/service-worker": "^17.3.4",
    "@angular/ssr": "^17.3.4",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/fontawesome-svg-core": "^6.5.2",
    "@fortawesome/free-brands-svg-icons": "^6.5.2",
    "@fortawesome/pro-regular-svg-icons": "^6.5.2",
    "@googlemaps/js-api-loader": "^1.16.6",
    "@ng-select/ng-select": "^12.0.7",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/schematics": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngrx/store-devtools": "^17.2.0",
    "@sentry/angular": "^8.17.0",
    "@sentry/cli": "^2.32.1",
    "@swimlane/ngx-charts": "^20.5.0",
    "@tailwindcss/typography": "^0.5.12",
    "@types/gsap": "^3.0.0",
    "express": "^4.19.2",
    "firebase-admin": "^12.0.0",
    "flexsearch": "^0.7.43",
    "gsap": "^3.12.5",
    "lottie-web": "^5.12.2",
    "ngx-cookie-service": "^17.1.0",
    "ngx-image-cropper": "^7.2.1",
    "ngx-infinite-scroll": "^17.0.0",
    "ngx-toastr": "^18.0.0",
    "prettier": "^3.2.5",
    "replace-in-file": "^7.1.0",
    "rxjs": "~7.8.1",
    "ssr-window": "^4.0.2",
    "tslib": "^2.6.2",
    "uuid": "^9.0.1",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.4",
    "@angular-eslint/builder": "17.3.0",
    "@angular-eslint/eslint-plugin": "17.3.0",
    "@angular-eslint/eslint-plugin-template": "17.3.0",
    "@angular-eslint/schematics": "17.3.0",
    "@angular-eslint/template-parser": "17.3.0",
    "@angular/cli": "~17.3.4",
    "@angular/compiler-cli": "^17.3.4",
    "@ngrx/eslint-plugin": "^17.2.0",
    "@types/d3-shape": "^3.1.6",
    "@types/elasticsearch": "^5.0.43",
    "@types/express": "^4.17.21",
    "@types/flexsearch": "^0.7.6",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^20.12.7",
    "@types/uuid": "^9.0.8",
    "@typescript-eslint/eslint-plugin": "7.6.0",
    "@typescript-eslint/parser": "7.6.0",
    "autoprefixer": "^10.4.19",
    "browser-sync": "^3.0.2",
    "daisyui": "^4.10.1",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.38",
    "prettier-eslint": "^16.3.0",
    "tailwindcss": "^3.4.3",
    "typescript": "~5.4.5"
  }
}
